import React from "react";
import "./TMHLoaderStyles.scss";
import LoaderImg from "./loader.png"

export default function TMHLoader() {
  return (
    <div className="loader-background">
      <img className="loader" src={LoaderImg} alt="tmh-loader" />
    </div>
  )
}