import React, { useState, useRef, useEffect } from "react";
import TMHLoader from "../../components/TMHLoader/TMHLoader";
import "./ContactPage.scss";
import { useTitle } from "react-use";
import validator from "validator";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactPage() {
    useTitle("Contact Page - Roku Remote App");
    const { site } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const captchaRef = useRef(null);
    const [isReCAPTCHAVerified, setIsReCAPTCHAVerified] = useState(false);

    // useEffect(() => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Cache-Control", "no-cache");

    //     var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: JSON.stringify({
    //             "event": {
    //                 "token": "TOKEN",
    //                 "expectedAction": "USER_ACTION",
    //                 "siteKey": "6LcSgisqAAAAAMlfvAdVko1568PcbvVzfp_CH9Af",
    //             }
    //         }),
    //     };
    //     fetch("https://recaptchaenterprise.googleapis.com/v1/projects/dataoptoutsubmission/assessments?key=AIzaSyAo9xYUX-8ri_0u9eJQ6AGh6Kk01CpRQOA", requestOptions)
    //         .then((response) => {
    //             console.log(response);
    //         })
    // }, [captchaRef.current]);

    function onReCAPTCHAChange(token) {
        setIsReCAPTCHAVerified(token != null);
    }

    async function handleSubmit() {
        setError("");
        setSuccess("");

        if (name === "") {
            setError("Your name is required!");
            return;
        } else if (email === "") {
            setError("Email is required!");
            return;
        } else if (!validator.isEmail(email)) {
            setError("Email Field is Invalid");
            return;
        } else {

            setIsReCAPTCHAVerified(false)
            captchaRef.current?.reset();
            setIsLoading(true);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cache-Control", "no-cache");

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    email: email,
                    subject: subject,
                    message: message,
                    name: name,
                    directly: "DataOptSubmission",
                    site: site ?? "TheMovieHouse",
                }),
            };

            fetch("https://themoviehouse.tv/api/users/contact_us", requestOptions)
                .then((response) => {
                    return new Promise((resolve) =>
                        response.json().then((json) =>
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json,
                            })
                        )
                    );
                })
                .then(
                    (result) => {
                        if (result.ok) {
                            setSuccess(result.json.message);
                            setName("");
                            setEmail("");
                            setMessage("");
                            setSubject("");
                        } else {
                            setError(result.json.message);
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        console.log(error);
                        setError(error.toString());
                        setIsLoading(false);
                    }
                );
        }
    }
    return (
        <>
            <div className="contact-container">
                <div className="contact-title">
                    Data Opt out <br /> Submission
                </div>
                <div style={{ color: "red" }}>{error}</div>
                <div style={{ color: "green" }}>{success}</div>
                <input
                    className="contact-input"
                    placeholder="Your Name *"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                />
                <input
                    className="contact-input"
                    placeholder=" Email *"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                />
                <input
                    className="contact-input"
                    placeholder="Subject"
                    value={subject}
                    onChange={({ target }) => setSubject(target.value)}
                />
                <div className="contact-text-area">
                    <textarea
                        placeholder="Message"
                        value={message}
                        onChange={({ target }) => setMessage(target.value)}
                    />
                </div>

                <p className="contact-mandatory">*mandatory field </p>
                <ReCAPTCHA
                    sitekey="6Lf4hywqAAAAAMF0TO40GTper2X2c59sEfJg6E7-"
                    ref={captchaRef}
                    onChange={onReCAPTCHAChange}
                />
                <button disabled={!isReCAPTCHAVerified} className="contact-send-button" onClick={() => handleSubmit()}>
                    SEND
                </button>
                <div className="directly-block">
                    <p className="contact-email-title">Email Directly:</p>
                    <p className="contact-email">privacy@dataoptoutsubmission.com</p>
                </div>
            </div>
            {isLoading && <TMHLoader />}
        </>
    );
}
