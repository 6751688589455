import { HashRouter, Route, Routes } from "react-router-dom"
import ContactPage from "./pages/ContactPage/ContactPage"

function App() {
  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={<ContactPage />} />
      <Route path="*" element={<ContactPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/:site" element={<ContactPage />} />
    </Routes>
  </HashRouter>
  );
}

export default App;
